"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenService = void 0;
const oidc_client_1 = require("oidc-client");
const env_resolver_1 = require("./env-resolver");
const baseUrl = `${window.location.protocol}//${window.location.host}`;
const defaults = {
    authority: (0, env_resolver_1.getAuthorityByEnv)(),
    client_id: (0, env_resolver_1.getClientIdByEnv)(),
    redirect_uri: `${baseUrl}/callback`,
    response_type: 'code',
    scope: 'openid profile sws',
    post_logout_redirect_uri: `${baseUrl}/home`,
    silent_redirect_uri: `${baseUrl}/silent-renew-callback`,
    automaticSilentRenew: false,
    loadUserInfo: false,
    filterProtocolClaims: true,
    accessTokenExpiringNotificationTime: 150,
    extraQueryParams: {},
};
class TokenService {
    constructor(options = {}) {
        this.options = { ...defaults, ...options };
    }
    get userManager() {
        if (this._userManager) {
            return this._userManager;
        }
        const userManager = new oidc_client_1.UserManager(this.options);
        userManager.events.addAccessTokenExpiring(() => {
            this.signinSilent();
        });
        userManager.events.addAccessTokenExpired(() => {
            this.redirectToLogin({
                url: window.location.pathname,
                search: window.location.search,
            });
        });
        userManager.events.addSilentRenewError((e) => {
            console.error('silent renew error... Error: ', e.message);
        });
        return (this._userManager = userManager);
    }
    signinSilentCallback() {
        return this.userManager.signinSilentCallback();
    }
    signinRedirectCallback() {
        return this.userManager.signinRedirectCallback();
    }
    signinSilent() {
        this.userManager.signinSilent();
    }
    redirectToLogout(state) {
        return state ? this.userManager.signoutRedirect({ state }) : this.userManager.signoutRedirect();
    }
    redirectToLogin(state) {
        return state ? this.userManager.signinRedirect({ state }) : this.userManager.signinRedirect();
    }
    async getToken() {
        const user = await this.userManager.getUser();
        return user ? user.access_token : null;
    }
}
exports.TokenService = TokenService;
