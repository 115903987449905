"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultController = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const streaming_1 = require("@guided-methods/streaming");
const uuid_1 = require("uuid");
const regexp = /guide\/Fe\/(.*)\/chunk\/(.*)\/(.*)/;
const defaultController = (gatewayUrl, http, logger, rootChannelId, streamingService) => {
    const messaging = (0, messaging_common_1.useMessagingOverHttp)(gatewayUrl)({ http, logger });
    return async (message) => {
        message.context = { ...message.context, rootChannelId };
        const match = message.to.match(regexp);
        if (message.type === messaging_common_1.GuideMessageType.Data && match) {
            console.debug(`Message to view chunk at '${message.to}' received\n ${JSON.stringify(message.data, null, 1)}`);
        }
        if ((message.to === streaming_1.createStreamingChannelV2.id || message.to === streaming_1.createStreamingChannel.id) &&
            message.type === messaging_common_1.GuideMessageType.Data) {
            const measurementId = (0, uuid_1.v4)();
            streamingService.storeRequest(message, measurementId);
            const data = message.to === streaming_1.createStreamingChannelV2.id
                ? {
                    type: streaming_1.StreamingServiceResponseType.Ok,
                    measurementId,
                    timeStarted: new Date(Date.now()).toDateString(),
                    status: 'Started',
                    signals: message.data.measurements,
                }
                : {
                    type: streaming_1.StreamingServiceResponseType.Ok,
                    measurementId,
                    timeStarted: new Date(Date.now()).toDateString(),
                    status: 'Started',
                };
            const response = {
                to: message.data.responseChannel,
                type: messaging_common_1.GuideMessageType.Data,
                context: message.context,
                data,
            };
            logger.debug(`DefaultController: In create stream ${measurementId}`);
            return await messaging.messageService.forward(response);
        }
        if (message.to === streaming_1.destroyStreamingChannel.id && message.type === messaging_common_1.GuideMessageType.Data) {
            logger.debug(`DefaultController: In destroy stream ${message.data.measurementId}`);
            streamingService.removeRequest(message?.data?.measurementId);
            const response = {
                to: message.data.responseChannel,
                type: messaging_common_1.GuideMessageType.Data,
                context: message.context,
                data: { type: streaming_1.StreamingServiceResponseType.Ok },
            };
            return await messaging.messageService.forward(response);
        }
        if (message.to === streaming_1.startStreamingChannel.id && message.type === messaging_common_1.GuideMessageType.Data) {
            logger.debug(`DefaultController: In start stream ${message?.data?.measurementId}`);
            streamingService.start(message);
            const response = {
                to: message.data.responseChannel,
                type: messaging_common_1.GuideMessageType.Data,
                context: message.context,
                data: {
                    type: streaming_1.StreamingServiceResponseType.Ok,
                    measurementId: message?.data?.measurementId,
                    streams: []
                },
            };
            return await messaging.messageService.forward(response);
        }
        if (message.to === streaming_1.stopStreamingChannel.id && message.type === messaging_common_1.GuideMessageType.Data) {
            logger.debug(`DefaultController: In stop stream ${message.data.measurementId}`);
            streamingService.stop(message);
            return;
        }
        if (!message.to.startsWith(rootChannelId)) {
            logger.debug({
                message: `Sending message outside: ${JSON.stringify(message)}`,
                ...message.context,
            });
            return await messaging.messageService.forward(message);
        }
    };
};
exports.defaultController = defaultController;
