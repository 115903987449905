"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLogFilter = exports.LogFilterLevel = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
var LogFilterLevel;
(function (LogFilterLevel) {
    LogFilterLevel[LogFilterLevel["Verbose"] = 0] = "Verbose";
    LogFilterLevel[LogFilterLevel["Lagom"] = 1] = "Lagom";
    LogFilterLevel[LogFilterLevel["Moderate"] = 2] = "Moderate";
})(LogFilterLevel || (exports.LogFilterLevel = LogFilterLevel = {}));
const getLogFilter = (level) => {
    switch (level) {
        case LogFilterLevel.Verbose:
            return (m) => m.level >= messaging_common_1.LogLevel.DEBUG;
        case LogFilterLevel.Moderate:
            return (m) => m.level >= messaging_common_1.LogLevel.ERROR || (m?.layer !== 'transport' && m.level >= messaging_common_1.LogLevel.INFO);
        default:
            return (m) => m.level >= messaging_common_1.LogLevel.ERROR ||
                (m?.layer !== 'transport' &&
                    (m.level >= messaging_common_1.LogLevel.INFO || (m?.mode !== null && m?.layer !== null)));
    }
};
exports.getLogFilter = getLogFilter;
