"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLibraryAssets = exports.SupportedAssetSources = void 0;
const deep_merge_1 = require("./deep-merge");
const env_resolver_1 = require("./env-resolver");
const load_translations_1 = require("./load-translations");
exports.SupportedAssetSources = {
    TDS: { repoName: 'sws-guide-ts-packages', assetPath: 'tds/assets/gui-strings.json' },
    HostApp: { repoName: 'sws-guide-host-app', assetPath: 'assets/gui-strings.json' },
};
const getLibraryAssets = async (sources, language, fullLocaleId) => {
    const bucketBaseUrl = (0, env_resolver_1.getTranslationUrlByEnv)();
    if (typeof bucketBaseUrl !== 'string') {
        return (0, load_translations_1.prepareLocalizedAsset)(fullLocaleId, {});
    }
    try {
        const promises = sources.map((s) => (0, load_translations_1.fetchAndPrepareLocalizedAsset)(bucketBaseUrl, s.repoName, fullLocaleId, s.assetPath, {})
            .catch((e) => (0, load_translations_1.prepareLocalizedAsset)(fullLocaleId, {}))
            .then((configuration) => configuration)
            .catch((e) => ({ language, messages: { [language]: {} } })));
        const configurations = await Promise.allSettled(promises);
        const messages = configurations.reduce((prev, curr) => (0, deep_merge_1.deepMerge)(prev, curr.status === 'fulfilled' ? curr.value.messages[language] : prev), {});
        const resultingConfiguration = { language, messages: { [language]: messages } };
        return resultingConfiguration;
    }
    catch (error) {
        console.error('Failed to retrieve library assets', error);
        return (0, load_translations_1.prepareLocalizedAsset)(fullLocaleId, {});
    }
};
exports.getLibraryAssets = getLibraryAssets;
