"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HostContext = void 0;
const react_1 = require("react");
exports.HostContext = (0, react_1.createContext)({
    rootChannelId: "",
    messageService: undefined,
    router: undefined,
    loadChunkControllerFactory: undefined,
    chunkHost: undefined,
    logger: undefined,
    tokenService: undefined,
    channels: undefined,
    http: undefined,
    guideDestroyers: undefined,
});
