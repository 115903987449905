"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepMerge = deepMerge;
var TYPE;
(function (TYPE) {
    TYPE[TYPE["Object"] = 0] = "Object";
    TYPE[TYPE["Array"] = 1] = "Array";
    TYPE[TYPE["Scalar"] = 2] = "Scalar";
    TYPE[TYPE["Undefined"] = 3] = "Undefined";
})(TYPE || (TYPE = {}));
function isUndefined(item) {
    return item === undefined;
}
function isArray(item) {
    return Array.isArray(item);
}
function isObject(item) {
    return item === null || Array.isArray(item) || typeof item === 'function'
        ? false
        : typeof item === 'object';
}
function deepMerge(to, from) {
    const fromType = detectType(from);
    if (fromType === TYPE.Undefined) {
        return copy(to, detectType(to));
    }
    if (fromType !== detectType(to)) {
        return copy(from, fromType);
    }
    return mergeSimilar(to, from, fromType);
}
function mergeSimilar(to, from, objType) {
    switch (objType) {
        case TYPE.Object:
            return mergeObjects(to, from);
        case TYPE.Array:
            return to.concat(from);
        default:
            return from;
    }
}
function mergeObjects(to, from) {
    const result = {};
    for (const p in to) {
        result[p] = deepMerge(to[p], from[p]);
    }
    for (const p in from) {
        if (!(p in result)) {
            result[p] = deepMerge(to[p], from[p]);
        }
    }
    return result;
}
function copy(obj, objType) {
    switch (objType) {
        case TYPE.Object:
            return Object.assign({}, obj);
        case TYPE.Array:
            return obj.slice();
        default:
            return obj;
    }
}
function detectType(obj) {
    if (isUndefined(obj)) {
        return TYPE.Undefined;
    }
    if (isArray(obj)) {
        return TYPE.Array;
    }
    if (isObject(obj)) {
        return TYPE.Object;
    }
    return TYPE.Scalar;
}
