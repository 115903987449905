"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageLog = void 0;
class MessageLog {
    constructor() {
        this.count = 0;
        this.messages = [];
        this.handlers = [];
    }
    on(handler) {
        this.handlers = [...this.handlers, handler];
        return () => {
            this.handlers = this.handlers.filter((h) => h !== handler);
        };
    }
    push(message) {
        const item = { ...message, timestamp: Date.now(), id: this.count++ };
        this.messages.push(item);
        this.handlers.forEach((h) => h(item));
    }
    get(filter) {
        return filter ? this.messages.filter(filter) : [...this.messages];
    }
}
exports.MessageLog = MessageLog;
