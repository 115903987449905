"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readGuideRequest = exports.setupReadGuideRequest = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const uuid_1 = require("uuid");
const models_1 = require("../models");
const stored_start_guide_request_1 = require("../models/stored-start-guide-request");
const url_helpers_1 = require("../utils/url-helpers");
const setupReadGuideRequest = (services, options, requestId) => {
    let iframeReady = false;
    let signalrReady = false;
    const unsubs = () => services.router.unsubscribe(services.channels.browserRunnerStatusChannel, controller);
    const controller = services.router.subscribe(services.channels.browserRunnerStatusChannel, async (m, _, r) => {
        if (m.type !== messaging_common_1.GuideMessageType.Data) {
            return await r.next();
        }
        switch (m.data.type) {
            case models_1.BrowserRunnerStatusMessageType.Error:
                const msg = 'Runner in invalid state and cannot process further requests';
                let logger = services.logger;
                logger.error({ mode: messaging_common_1.LogMode.Framework, message: msg });
                try {
                    const data = await readRequest(services, options, requestId);
                    const context = data.context;
                    const request = (0, messaging_common_1.validate)(stored_start_guide_request_1.StoredStartGuideRequest, data);
                    if (request.Data.upstreamChannel) {
                        let upstreamMessage = {
                            type: messaging_common_1.GuideUpstreamMessageType.GuideError,
                            errorMessage: msg,
                        };
                        services.messageService.send(request.Data.upstreamChannel, messaging_common_1.GuideMessageType.Data, context, upstreamMessage);
                        services.messageService.send(request.Data.upstreamChannel, messaging_common_1.GuideMessageType.EndChannel, context);
                    }
                    else {
                        logger.warn({
                            mode: messaging_common_1.LogMode.Framework,
                            message: 'No upstream channel provided for passing runner status errors',
                            ...context,
                        });
                    }
                }
                catch (e) {
                    logger.error({
                        mode: messaging_common_1.LogMode.Framework,
                        message: 'Failed to send runner status error upstream',
                        ...m.context,
                        e,
                    });
                    return;
                }
                break;
            case models_1.BrowserRunnerStatusMessageType.IframeHostReady:
                iframeReady = true;
                break;
            case models_1.BrowserRunnerStatusMessageType.SignalRConnected:
                signalrReady = true;
                break;
        }
        if (iframeReady && signalrReady) {
            (0, exports.readGuideRequest)(services, options, requestId);
            unsubs();
        }
        return await r.next();
    });
    return unsubs;
};
exports.setupReadGuideRequest = setupReadGuideRequest;
const sendPreconditionsCheckStarted = (startRequest, conditions, services) => {
    services.messageService.send(services.channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, startRequest.Data.context, {
        type: models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckStarted,
        conditions,
        requestId: startRequest.RequestId,
    });
};
const sendPreconditionCheckFailed = (startRequest, conditions, services, conditionResults, retryCallback, abortCallback, resolvedCallback) => {
    const responseChannel = (0, messaging_common_1.createChannel)(models_1.ConditionFailureConfirmationResponseMessage, (0, uuid_1.v4)());
    services.router.subscribe(responseChannel, async (m, t, router) => {
        handleConditionFailureResponse(m, retryCallback, abortCallback, resolvedCallback);
    });
    services.messageService.send(services.channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, startRequest.Data.context, {
        type: models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed,
        conditions,
        requestId: startRequest.RequestId,
        conditionResults,
        resolvingFailure: true,
        responseChannel,
    });
};
const sendPreconditionsDidNotPass = (startRequest, conditions, services, conditionResults, retryCallback, abortCallback, resolvedCallback) => {
    const responseChannel = (0, messaging_common_1.createChannel)(models_1.ConditionFailureConfirmationResponseMessage, (0, uuid_1.v4)());
    services.router.subscribe(responseChannel, async (m, t, router) => {
        if (m.type === messaging_common_1.GuideMessageType.Data) {
            switch (m.data.type) {
                case 'Retry':
                    retryCallback(m.data.requestId, m.data.conditions);
                    break;
                case 'Abort':
                    abortCallback(m.data.requestId, m.data.conditions);
                    break;
                case 'ForceProceed':
                    resolvedCallback(m.data.requestId, m.data.conditions);
                    break;
                default:
                    throw new Error('Unexpected ConditionFailureConfirmationResponseMessage message');
            }
        }
    });
    services.messageService.send(services.channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, startRequest.Data.context, {
        type: models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed,
        conditions,
        requestId: startRequest.RequestId,
        conditionResults,
        resolvingFailure: false,
        responseChannel,
    });
};
const sendPreconditionsPassed = (services, conditions, startRequest) => {
    services.messageService.send(services.channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, startRequest.Data.context, {
        type: models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckPassed,
        conditions,
        requestId: startRequest.RequestId,
    });
};
const readGuideRequest = async (deps, options, requestId) => {
    const { channels, logger, messageService } = deps;
    let data, request;
    try {
        data = await readRequest(deps, options, requestId);
        request = (0, messaging_common_1.validate)(stored_start_guide_request_1.StoredStartGuideRequest, data);
        const parameters = (0, url_helpers_1.getQueryParameters)();
        request.Data.context = {
            ...request.Data.context,
            locale: parameters?.lang ?? navigator.language,
        };
        await initiateLaunch(deps, options, request);
    }
    catch (e) {
        const message = `Failed to handle guide launch request [${requestId}]: ${e instanceof Error ? e.message : "Unknown error"}`;
        console.error(message);
        logger.error(message, e instanceof Error ? e : undefined);
        await messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
            type: models_1.BrowserRunnerStatusMessageType.Error,
            message,
        });
        if (request?.Data?.upstreamChannel) {
            await messageService.send(request?.Data?.upstreamChannel, messaging_common_1.GuideMessageType.Data, request?.Data?.context, {
                type: messaging_common_1.GuideUpstreamMessageType.GuideLoadingFailed,
                errorMessage: message,
            });
            await messageService.send(request?.Data?.upstreamChannel, messaging_common_1.GuideMessageType.EndChannel, request?.Data?.context);
        }
    }
};
exports.readGuideRequest = readGuideRequest;
const createRetryCallback = (deps, options, request) => async (requestId, conditions) => {
    deps.logger.info({
        mode: messaging_common_1.LogMode.Framework,
        message: `Preconditions not fulfilled for request ${requestId}. Retry requested for ${conditions
            .map((c) => c.operationReference)
            .join(',')}`,
        ...request.Data.context,
    });
    await initiateLaunch(deps, options, request);
};
const createAbortCallback = (deps, options, request) => async (_requestId, _conditions) => {
    deps.logger.info({
        mode: messaging_common_1.LogMode.Framework,
        message: 'Preconditions could not be evaluated. Guide launch abortion requested',
        ...request.Data.context,
    });
    deps.messageService.send(request.Data.upstreamChannel, messaging_common_1.GuideMessageType.Data, request.Data?.context, {
        type: messaging_common_1.GuideUpstreamMessageType.GuideLoadingFailed,
        errorMessage: 'Preconditions could not be evaluated. Aborting guide loading...',
    });
    deps.messageService.send(request.Data.upstreamChannel, messaging_common_1.GuideMessageType.EndChannel, {});
};
const createContinuationCallback = (deps, options, request) => async (requestId, _conditions) => {
    deps.logger.warn({
        mode: messaging_common_1.LogMode.Framework,
        message: `Precondition failure for request ${requestId}. User opted for forced start. Initiating guide launch`,
        ...request.Data.context,
    });
    launchGuide(deps, options.rootChannelId, request);
};
const initiateLaunch = async (deps, options, request) => {
    const { logger, messageService, preconditionsEvaluator, launchDarklyService } = deps;
    if (launchDarklyService) {
        let ldPreconditionsOn;
        try {
            ldPreconditionsOn = await launchDarklyService.featureToggleActive('guide-preconditions-on');
        }
        catch (e) {
            deps.logger.error({ message: `Failed to check feature permissions. Aborting with error: ${e instanceof Error ? e.message : "Unknown error"}` });
            throw e;
        }
        if (ldPreconditionsOn && hasPreconditions(request)) {
            await checkPreconditions(deps, options, request, preconditionsEvaluator, launchGuide);
        }
        else {
            launchGuide(deps, options.rootChannelId, request);
        }
    }
    else {
        launchGuide(deps, options.rootChannelId, request);
    }
};
const readRequest = async (deps, options, requestId) => {
    try {
        const { http } = deps;
        const response = await http.get(`${options.guideRequestServiceUrl}api/v1/request/${requestId}`);
        if (response.status !== 200) {
            throw new Error(`${response.status} from request storage service`);
        }
        else {
            return { ...response.data, Data: { ...response.data.Data, requestId } };
        }
    }
    catch (e) {
        deps.logger.error({ message: `Failed to read request: ${e instanceof Error ? e.message : "Unknown error"}` });
        throw e;
    }
};
const handleConditionFailureResponse = (m, retryCallback, abortCallback, resolvedCallback) => {
    if (m.type === messaging_common_1.GuideMessageType.Data) {
        switch (m.data.type) {
            case 'Retry':
                retryCallback(m.data.requestId, m.data.conditions);
                break;
            case 'Abort':
                abortCallback(m.data.requestId, m.data.conditions);
                break;
            case 'ForceProceed':
                resolvedCallback(m.data.requestId, m.data.conditions);
                break;
            default:
                throw new Error('Unexpected ConditionFailureConfirmationResponseMessage message');
        }
    }
};
function hasPreconditions(request) {
    return (request.Data?.initialData['parameters'] &&
        request.Data?.initialData['parameters']['preConditions'] &&
        request.Data?.initialData['parameters']['preConditions'].length > 0 &&
        request.Data?.context?.disablePreconditionsCheck !== true);
}
async function checkPreconditions(deps, options, request, preconditionsEvaluator, launchTheGuide) {
    if (!hasPreconditions(request)) {
        launchTheGuide(deps, options.rootChannelId, request);
        return;
    }
    deps.logger.info({
        layer: messaging_common_1.LogLayerNames.Performance,
        mode: messaging_common_1.LogMode.Framework,
        message: 'Preconditions evaluation started',
        ...request.Data.context,
    });
    const opRG = request.Data;
    if (opRG == undefined) {
        deps.logger.warn({ message: 'Expected start request to be one for Guide Realizing an Operation', layer: messaging_common_1.LogLayerNames.Messaging });
        launchTheGuide(deps, options.rootChannelId, request);
        return;
    }
    const conditions = opRG.initialData.parameters?.preConditions ?? [];
    if (conditions.length === 0) {
        deps.logger.debug({ message: 'No Preconditions defined. Launching anyway', layer: messaging_common_1.LogLayerNames.Messaging });
        launchTheGuide(deps, options.rootChannelId, request);
        return;
    }
    sendPreconditionsCheckStarted(request, conditions, deps);
    await preconditionsEvaluator({
        type: 'Once',
        params: {
            conditions,
            context: request.Data.context,
            requestId: request.RequestId,
        },
        done: async (_requestId, results, error) => {
            deps.logger.info({
                layer: messaging_common_1.LogLayerNames.Performance,
                mode: messaging_common_1.LogMode.Framework,
                message: 'Preconditions evaluation finished',
                ...request.Data.context,
            });
            const abortGuideLoadingCallback = createAbortCallback(deps, options, request);
            const retryConditionChecksCallback = createRetryCallback(deps, options, request);
            const continuationCallback = createContinuationCallback(deps, options, request);
            if (error) {
                sendPreconditionCheckFailed(request, conditions, deps, results, retryConditionChecksCallback, abortGuideLoadingCallback, continuationCallback);
                return;
            }
            if (!error &&
                results?.length > 0 &&
                results.find((r) => r.result.type !== 'Ok' || !r.result.passed) !== undefined) {
                sendPreconditionsDidNotPass(request, conditions, deps, results, retryConditionChecksCallback, abortGuideLoadingCallback, continuationCallback);
                return;
            }
            deps.logger.info({
                mode: messaging_common_1.LogMode.Framework,
                message: 'Preconditions fulfilled. Guide launch initiated',
                ...request.Data.context,
            });
            sendPreconditionsPassed(deps, conditions, request);
            launchTheGuide(deps, options.rootChannelId, request);
        },
    });
}
const launchGuide = async (deps, rootChannelId, request) => {
    const { channels, logger, messageService, router } = deps;
    const startChannel = (0, messaging_common_1.createChannel)(messaging_common_1.StartGuideRequest, channels.startGuideChannel.id);
    const upstreamChannelInput = request.Data.upstreamChannel;
    const controller = router.subscribe(upstreamChannelInput, async (m, t, router) => {
        let timer;
        let controlChannel;
        if (m.type === messaging_common_1.GuideMessageType.Data) {
            if (m.data.type === messaging_common_1.GuideUpstreamMessageType.GuideLoadingStarted) {
                deps.logger.info({
                    message: `Guide loading started`,
                    ...request.Data.context,
                    requestId: request.Data.requestId,
                    status: "guide-loading-started",
                    guideStatus: undefined
                });
                controlChannel = m.data.controlChannel;
                timer = setInterval(() => {
                    messageService.send(controlChannel, messaging_common_1.GuideMessageType.KeepAlive, request.Data?.context, {});
                }, 5000);
            }
            else {
                if (timer) {
                    clearInterval(timer);
                }
            }
        }
        if (m.type === messaging_common_1.GuideMessageType.EndChannel) {
            if (timer) {
                clearInterval(timer);
            }
            await messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, m.context, {
                type: models_1.BrowserRunnerStatusMessageType.GuideCompleted,
            });
            router.unsubscribe(upstreamChannelInput, controller);
        }
        return await router.next();
    });
    messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, request.Data?.context ?? {}, {
        type: models_1.BrowserRunnerStatusMessageType.GuideStarting,
        requestId: request.Data.requestId
    });
    await messageService.send(startChannel, messaging_common_1.GuideMessageType.Data, request.Data?.context, {
        ...request.Data,
        browserChannelRoot: rootChannelId,
        executionId: (0, uuid_1.v4)(),
    });
};
