"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupRunnerStatusChannels = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const models_1 = require("../models");
const setupRunnerStatusChannels = (rootChannelId) => {
    const browserRunnerStatusChannelId = (0, messaging_common_1.combine)(rootChannelId, 'runner-status');
    const browserRunnerStatusChannel = (0, messaging_common_1.createChannel)(models_1.BrowserRunnerStatusMessage, browserRunnerStatusChannelId);
    return { browserRunnerStatusChannel };
};
exports.setupRunnerStatusChannels = setupRunnerStatusChannels;
