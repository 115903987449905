"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBrowserConsoleLogger = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const createBrowserConsoleLogger = (options) => (message) => {
    const date = message.timestamp ? new Date(message.timestamp) : Date.now();
    const formattedDate = new Intl.DateTimeFormat('sv-SE', {
        dateStyle: 'short',
        timeStyle: 'short',
    }).format(date);
    const chunk = message.chunkId ?? 'host';
    if (options.showXstateLayerLogging &&
        (!('layer' in message) || ('layer' in message && message.layer !== 'xstate'))) {
        return;
    }
    switch (message.level) {
        case messaging_common_1.LogLevel.DEBUG:
            console.debug(`[${formattedDate}, ${chunk}]`, message.message, message);
            break;
        case messaging_common_1.LogLevel.INFO:
            console.log(`[${formattedDate}, ${chunk}]`, message.message, message);
            break;
        case messaging_common_1.LogLevel.WARNING:
            console.warn(`[${formattedDate}, ${chunk}]`, message.message, message);
            break;
        case messaging_common_1.LogLevel.ERROR:
            console.error(`[${formattedDate}, ${chunk}]`, message.message, message);
            break;
        default:
            console.table(message);
    }
};
exports.createBrowserConsoleLogger = createBrowserConsoleLogger;
