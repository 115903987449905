"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeFullUrl = exports.getQueryParameters = void 0;
const getQueryParameters = () => {
    const queryStringParams = new URLSearchParams(window.location?.search ?? "");
    const lang = queryStringParams.get('lang');
    const showLocalLogs = queryStringParams.get('showLocalLogs') === 'true';
    const showVerboseLogging = queryStringParams.get('showVerboseLogging') === 'true';
    const showXstateLayerLogging = queryStringParams.get('showXstateLayerLogging') === 'true';
    return { lang: lang ?? "", showLocalLogs, showVerboseLogging, showXstateLayerLogging };
};
exports.getQueryParameters = getQueryParameters;
const makeFullUrl = (url, bucketUrl) => {
    const u = url.startsWith('http') ? url : bucketUrl + url;
    let fUrl = new URL(u);
    const { lang } = (0, exports.getQueryParameters)();
    if (lang) {
        fUrl.searchParams.append('lang', lang ?? "");
    }
    return fUrl.toString();
};
exports.makeFullUrl = makeFullUrl;
