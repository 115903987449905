"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoredStartGuideRequestManually = exports.StoredStartGuideRequest = void 0;
const io_ts_1 = require("io-ts");
const messaging_common_1 = require("@guided-methods/messaging-common");
exports.StoredStartGuideRequest = (0, io_ts_1.type)({
    RequestId: io_ts_1.string,
    Expiration: io_ts_1.number,
    Data: (0, io_ts_1.type)({
        channelId: io_ts_1.string,
        requestId: io_ts_1.string,
        guideId: io_ts_1.string,
        preferBrowser: io_ts_1.boolean,
        initialData: io_ts_1.unknown,
        context: io_ts_1.any,
        upstreamChannel: (0, messaging_common_1.GuideMessageChannel)(messaging_common_1.GuideUpstreamMessage),
    }, 'MandatoryGuideLaunchData'),
}, 'StoredStartGuideRequest');
exports.StoredStartGuideRequestManually = (0, io_ts_1.type)({
    requestId: io_ts_1.string,
    guideId: io_ts_1.string,
    preferBrowser: io_ts_1.boolean,
    upstreamChannel: (0, messaging_common_1.GuideMessageChannel)(messaging_common_1.GuideUpstreamMessage),
});
