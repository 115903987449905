"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupRouting = void 0;
const chunk_host_1 = require("@guided-methods/chunk-host");
const controllers_1 = require("../controllers");
const setupRouting = (services) => {
    const { router, channels, defaultController, loadChunkControllerFactory, guideStorage } = services;
    (0, chunk_host_1.setupChunkHostRoutes)(router, services);
    router.subscribe(channels.loadChunkChannel, (0, controllers_1.loadChunkControllerWebWorker)(loadChunkControllerFactory, guideStorage));
    router.default(defaultController);
};
exports.setupRouting = setupRouting;
